import React, { useState, useEffect, useRef } from "react";

export interface hotelData {
  brand: string;
  title: string;
  address: string;
  mapUrl: string;
  tel: string;
  location: string;
  access: Array<string>;
  tag: Array<string>;
  hid: string;
  synxis?: {
    chain: string;
    config?: string;
    configcode?: string;
    hotel: string;
    shell?: string;
    template?: string;
    theme?: string;
    themecode?: string;
  };
  siteUrl: string;
  img: {
    src: string;
  };
  regions: string;
}

interface searchCondition {
  label?: string;
  value: string;
}

export const regions: Array<searchCondition> = [
  {
    value: "北海道",
  },
  {
    value: "東北",
  },
  {
    value: "関東",
  },
  {
    value: "中部",
  },
  {
    value: "関西",
  },
  {
    value: "中国",
  },
  {
    value: "九州",
  },
  {
    value: "沖縄",
  },
];

export const areas: Array<searchCondition> = [
  {
    value: "札幌",
  },
  {
    value: "仙台",
  },
  {
    value: "東京",
  },
  {
    value: "横浜",
  },
  {
    value: "名古屋",
  },
  {
    value: "京都",
  },
  {
    value: "大阪",
  },
  {
    value: "神戸",
  },
  {
    value: "広島",
  },
  {
    value: "福岡",
  },
  {
    value: "沖縄",
  },
];

export const tags: Array<searchCondition> = [
  {
    value: "#ウェルネス",
  },
  {
    value: "#クラブフロア",
  },
  {
    value: "#夜景",
  },
  {
    value: "#記念日",
  },
  {
    value: "#イルミネーション",
  },
  {
    value: "#アフタヌーンティー",
  },
  {
    value: "#日本料理",
  },
  {
    value: "#中国料理",
  },
  {
    value: "#フランス料理",
  },
  {
    value: "#イタリア料理",
  },
  {
    value: "#バー・ラウンジ",
  },
  {
    value: "#スイーツショップ",
  },
  {
    value: "#大浴場",
  },
  {
    value: "#サウナ",
  },
  {
    value: "#ビューバス",
  },
  {
    value: "#新規開業（2020年以降）",
  },
  {
    value: "#リニューアル（2020年以降）",
  },
];

const fullService: Array<hotelData> = [
  {
    brand: "srph",
    title: "仙台ロイヤルパークホテル",
    address: "〒981-3204 仙台市泉区寺岡6-2-1",
    mapUrl: "https://goo.gl/maps/XRvJgidDiPs6oDHF7",
    tel: "022-377-1111",
    location: "仙台",
    access: [
      "JR「仙台」駅より車で約30分",
      "仙台市地下鉄南北線「泉中央」駅より車で約15分",
    ],
    hid: "rphs_sendai",
    siteUrl: "https://www.srph.co.jp/",
    img: {
      src: "/assets/images/hotels/srph/img_sendai.png",
    },
    tag: [
      "仙台",
      "#ウェルネス",
      "#記念日",
      "#イルミネーション",
      "#アフタヌーンティー",
      "#中国料理",
      "#フランス料理",
      "#バー・ラウンジ",
      "#ビューバス",
    ],
    regions: "東北",
  },
  {
    brand: "rph",
    title: "ロイヤルパークホテル",
    address: "〒103-8520 東京都中央区日本橋蛎殻町2-1-1",
    mapUrl: "https://goo.gl/maps/CjYjdZNydSQ77RYJ8",
    tel: "03-3667-1111",
    location: "東京・日本橋",
    access: [
      "東京メトロ半蔵門線「水天宮前」駅 直結",
      "東京メトロ日比谷線「人形町」駅（A2出口） 徒歩約5分",
      "都営浅草線「人形町」駅（A3出口） 徒歩約8分",
    ],
    hid: "rphs_nihonbashi",
    synxis: {
      chain: "10237",
      hotel: "27153",
      shell: "rNRTRP",
      template: "rNRTRP",
    },
    siteUrl: "https://www.rph.co.jp/",
    img: {
      src: "assets/images/hotels/rph/img_nihonbashi.png",
    },
    tag: [
      "東京",
      "#ウェルネス",
      "#クラブフロア",
      "#夜景",
      "#記念日",
      "#アフタヌーンティー",
      "#日本料理",
      "#中国料理",
      "#フランス料理",
      "#バー・ラウンジ",
      "#スイーツショップ",
    ],
    regions: "関東",
  },
  {
    brand: "yrph",
    title: "横浜ロイヤルパークホテル ",
    address: "〒220-8173 横浜市西区みなとみらい2-2-1-3",
    mapUrl: "https://goo.gl/maps/jYUYJh8SozF71EP96",
    tel: "045-221-1111",
    location: "横浜",
    access: [
      "横浜高速鉄道みなとみらい線「みなとみらい」駅 徒歩約3分",
      "JR「桜木町」駅 徒歩約5分",
      "横浜市営地下鉄「桜木町」駅 徒歩約5分",
    ],
    hid: "rphs_yokohama",
    synxis: {
      chain: "10237",
      hotel: "27337",
      shell: "rNRTYR",
      template: "rNRTYR",
    },
    siteUrl: "https://www.yrph.com/",
    img: {
      src: "/assets/images/hotels/yrph/img_yokohama.png",
    },
    tag: [
      "横浜",
      "#ウェルネス",
      "#クラブフロア",
      "#夜景",
      "#記念日",
      "#イルミネーション",
      "#アフタヌーンティー",
      "#日本料理",
      "#中国料理",
      "#フランス料理",
      "#バー・ラウンジ",
      "#スイーツショップ",
      "#サウナ",
      "#ビューバス",
      "#リニューアル（2020年以降）",
    ],
    regions: "関東",
  },
];

const iconic: Array<hotelData> = [
  {
    brand: "iconic",
    title: "ザ ロイヤルパークホテル アイコニック 東京汐留",
    address: "〒105-8333 東京都港区東新橋1-6-3",
    mapUrl: "https://goo.gl/maps/RQNjn4anQ16jLdgYA",
    tel: "03-6253-1111",
    location: "東京・汐留",
    access: [
      "JR「新橋」駅（汐留口） 徒歩約3分",
      "東京メトロ銀座線「新橋」駅 徒歩約5分",
      "都営浅草線「新橋」駅 徒歩約3分 ",
      "都営大江戸線「汐留」駅（2番出口） 徒歩約1分",
      "新交通ゆりかもめ「汐留」駅（西出口） 徒歩約1分",
    ],
    hid: "rphs_icshiodome",
    synxis: {
      chain: "10237",
      config: "TYOST_NEW",
      configcode: "TYOST_NEW",
      hotel: "27154",
      theme: "TYOST_NEW",
      themecode: "TYOST_NEW",
    },
    siteUrl: "https://www.royalparkhotels.co.jp/ic/tokyoshiodome/",
    img: {
      src: "/assets/images/hotels/iconic/img_tokyoshiodome.png",
    },
    tag: [
      "東京",
      "#夜景",
      "#記念日",
      "#アフタヌーンティー",
      "#バー・ラウンジ",
      "#スイーツショップ",
    ],
    regions: "関東",
  },
  {
    brand: "iconic",
    title:
      "ザ ロイヤルパークホテル アイコニック 名古屋",
    address: "〒460-0008 名古屋市中区栄4-1-1 中日ビル24F",
    mapUrl: "https://goo.gl/maps/YqJhhGmKFeA7Kzrc9",
    tel: "052-269-1118",
    location: "名古屋",
    access: ["名古屋市営地下鉄東山線・名城線「栄」駅 直結"],
    hid: "rphs_icnagoya",
    siteUrl: "https://www.royalparkhotels.co.jp/ic/nagoya/",
    img: {
      src: "/assets/images/hotels/iconic/img_nagoya.png",
    },
    tag: [
      "名古屋",
      "#夜景",
      "#記念日",
      "#中国料理",
      "#バー・ラウンジ",
      "#サウナ",
      "#新規開業（2020年以降）",
    ],
    regions: "中部",
  },
  {
    brand: "iconic",
    title: "ザ ロイヤルパークホテル アイコニック 京都",
    address: "〒604-0836 京都市中京区船屋町420",
    mapUrl: "https://goo.gl/maps/GwSr2gWR16UVSAnj7",
    tel: "075-241-1110",
    location: "京都",
    access: ["京都市営地下鉄烏丸線・東西線「烏丸御池」駅（1番出口） 徒歩約2分"],
    hid: "rphs_ickyoto",
    siteUrl: "https://www.royalparkhotels.co.jp/ic/kyoto/",
    img: {
      src: "/assets/images/hotels/iconic/img_kyoto.png",
    },
    tag: [
      "京都",
      "#ウェルネス",
      "#クラブフロア",
      "#記念日",
      "#イタリア料理",
      "#バー・ラウンジ",
      "#スイーツショップ",
      "#大浴場",
      "#ビューバス",
      "#新規開業（2020年以降）",
    ],
    regions: "関西",
  },
  {
    brand: "iconic",
    title: "ザ ロイヤルパークホテル アイコニック 大阪御堂筋",
    address: "〒541-0046 大阪市中央区平野町4-2-3 オービック御堂筋ビル",
    mapUrl: "https://goo.gl/maps/d9cyqfg3GscEvsnP9",
    tel: "06-4707-1111",
    location: "大阪",
    access: [
      "大阪メトロ御堂筋線「淀屋橋」駅（13号出口） 徒歩約3分",
      "大阪メトロ御堂筋線・中央線「本町」駅（2号出口） 徒歩約4分",
      "大阪メトロ御堂筋線・JR「新大阪」駅から京阪電車「北浜」駅まで 最短約16分",
    ],
    hid: "rphs_icmidosuji",
    siteUrl: "https://www.royalparkhotels.co.jp/ic/osakamidosuji/",
    img: {
      src: "/assets/images/hotels/iconic/img_osakamidosuji.png",
    },
    tag: [
      "大阪",
      "#クラブフロア",
      "#夜景",
      "#記念日",
      "#イルミネーション",
      "#アフタヌーンティー",
      "#フランス料理",
      "#バー・ラウンジ",
      "#ビューバス",
      "#新規開業（2020年以降）",
    ],
    regions: "関西",
  },
];

const the: Array<hotelData> = [
  {
    brand: "the",
    title: "ザ ロイヤルパークホテル 東京羽田",
    address:
      "〒144-0041 東京都大田区羽田空港2-6-5 羽田空港第3旅客ターミナルビル内",
    mapUrl: "https://goo.gl/maps/qcifMc8ryPxa9ZPG8",
    tel: "03-6830-1111",
    location: "東京・羽田",
    access: [
      "東京モノレール「羽田空港第3ターミナル」駅 徒歩約3分",
      "京浜急行 空港線「羽田空港第3ターミナル」駅 徒歩約5分",
    ],
    hid: "rphs_thehaneda",
    siteUrl: "https://www.royalparkhotels.co.jp/the/tokyohaneda/",
    img: {
      src: "/assets/images/hotels/the/img_tokyohaneda.png",
    },
    tag: ["東京", "#バー・ラウンジ"],
    regions: "関東",
  },
  {
    brand: "the",
    title: "ザ ロイヤルパークホテル 銀座6丁目",
    address: "〒104-0061 東京都中央区銀座6-16-14",
    mapUrl: "https://maps.app.goo.gl/uytgPHYDviVHMCG26",
    tel: "03-3541-1116",
    location: "東京・銀座",
    access: ["東京メトロ・都営浅草線「東銀座」駅<br />（4・A1出口）徒歩約3分"],
    hid: "rphs_theginza6",
    siteUrl: "https://www.royalparkhotels.co.jp/the/ginza6/",
    img: {
      src: "/assets/images/hotels/the/img_ginza6.png",
    },
    tag: ["東京", "#バー・ラウンジ", "#大浴場", "#新規開業（2020年以降）"],
    regions: "関東",
  },
  {
    brand: "the",
    title: "ザ ロイヤルパークホテル 京都三条",
    address: "〒604-8004 京都市中京区中島町74",
    mapUrl: "https://goo.gl/maps/rSjS8XgQShvj9j3h8",
    tel: "075-241-1111",
    location: "京都",
    access: [
      "京阪本線「三条」駅 徒歩約3分",
      "京都市営地下鉄東西線「京都市役所前」駅 徒歩約3分",
    ],
    hid: "rphs_thesanjo",
    siteUrl: "https://www.royalparkhotels.co.jp/the/kyotosanjo/",
    img: {
      src: "/assets/images/hotels/the/img_kyotosanjo.png",
    },
    tag: ["京都", "#バー・ラウンジ", "#リニューアル（2020年以降）"],
    regions: "関西",
  },
  {
    brand: "the",
    title: "ザ ロイヤルパークホテル 京都四条",
    address: "〒600-8412 京都市下京区二帖半敷町668",
    mapUrl: "https://goo.gl/maps/MyMAGp2LExh4PnDo9",
    tel: "075-351-1111",
    location: "京都",
    access: ["京都市営地下鉄烏丸線「四条」駅（6番出口） 徒歩約1分"],
    hid: "rphs_theshijo",
    siteUrl: "https://www.royalparkhotels.co.jp/the/kyotoshijo/",
    img: {
      src: "/assets/images/hotels/the/img_kyotoshijo.png",
    },
    tag: ["京都", "#アフタヌーンティー"],
    regions: "関西",
  },
  {
    brand: "the",
    title: "ザ ロイヤルパークホテル 京都梅小路",
    address: "〒600-8835 京都市下京区観喜寺町9-3",
    mapUrl: "https://goo.gl/maps/D4M64fZ95MNBArro7",
    tel: "075-354-1111",
    location: "京都",
    access: ["JR嵯峨野線「梅小路京都西」駅 徒歩約1分"],
    hid: "rphs_theumekoji",
    siteUrl: "https://www.royalparkhotels.co.jp/the/kyotoumekoji/",
    img: {
      src: "/assets/images/hotels/the/img_kyotoumekoji.png",
    },
    tag: ["京都", "#大浴場", "#新規開業（2020年以降）"],
    regions: "関西",
  },
  {
    brand: "the",
    title: "ザ ロイヤルパークホテル 広島リバーサイド",
    address: "〒730-0014 広島市中区上幟町7-14",
    mapUrl: "https://goo.gl/maps/SikY6o9foPT2eyDE6",
    tel: "082-211-1111",
    location: "広島",
    access: ["JR「広島」駅（南口） 徒歩約8分"],
    hid: "rphs_thehiroshima",
    siteUrl: "https://www.royalparkhotels.co.jp/the/hiroshimars/",
    img: {
      src: "/assets/images/hotels/the/img_hiroshimariverside.png",
    },
    tag: ["広島", "#アフタヌーンティー"],
    regions: "中国",
  },
  {
    brand: "the",
    title: "ザ ロイヤルパークホテル 福岡",
    address: "〒812-0011 福岡市博多区博多駅前2-14-15",
    mapUrl: "https://goo.gl/maps/gE1ottMvSrKDhRBU6",
    tel: "092-414-1111",
    location: "福岡",
    access: [
      "JR「博多」駅（博多口） 徒歩約5分",
      "福岡市地下鉄「博多」駅（博多口） 徒歩約5分",
    ],
    hid: "rphs_thefukuoka",
    siteUrl: "https://www.royalparkhotels.co.jp/the/fukuoka/",
    img: {
      src: "/assets/images/hotels/the/img_fukuoka.png",
    },
    tag: [
      "福岡",
      "#アフタヌーンティー",
      "#中国料理",
      "#バー・ラウンジ",
      "#リニューアル（2020年以降）",
    ],
    regions: "九州",
  },
];

const canvas: Array<hotelData> = [
  {
    brand: "canvas",
    title: "ザ ロイヤルパーク キャンバス 札幌大通公園",
    address: "〒060-0042 札幌市中央区大通西1-12",
    mapUrl: "https://goo.gl/maps/XCHKzxCHvt4AZP8BA",
    tel: "011-208-1555",
    location: "札幌",
    access: [
      "札幌市営地下鉄「大通」駅（23番出口） 徒歩約1分",
      "JR「札幌」駅 徒歩約15分（札幌駅前通地下歩行空間利用）",
    ],
    hid: "rphs_csapporoodori",
    siteUrl: "https://www.royalparkhotels.co.jp/canvas/sapporoodoripark/",
    img: {
      src: "/assets/images/hotels/canvas/img_sapporoodori.png",
    },
    tag: [
      "札幌",
      "#夜景",
      "#フランス料理",
      "#バー・ラウンジ",
      "#新規開業（2020年以降）",
    ],
    regions: "北海道",
  },
  {
    brand: "canvas",
    title: "ザ ロイヤルパーク キャンバス 銀座8",
    address: "〒104-0061 東京都中央区銀座8-9-4",
    mapUrl: "https://goo.gl/maps/3qeBdEmtEnLPg9379",
    tel: "03-6205-8010",
    location: "東京・銀座",
    access: [
      "東京メトロ「銀座」駅（A4出口） 徒歩約5分",
      "JR「新橋」駅（銀座口） 徒歩約7分",
      "都営浅草線「新橋」駅（A3出口）徒歩約7分",
      "新交通ゆりかもめ「新橋」駅（1C出口） 徒歩約7分",
    ],
    hid: "rphs_ginza8",
    siteUrl: "https://www.canvas-ginza8.jp/",
    img: {
      src: "/assets/images/hotels/canvas/img_ginza8.png",
    },
    tag: ["東京", "#アフタヌーンティー", "#バー・ラウンジ"],
    regions: "関東",
  },
  {
    brand: "canvas",
    title: "ザ ロイヤルパーク キャンバス 銀座コリドー",
    address: "〒104-0061 東京都中央区銀座6-2-11",
    mapUrl: "https://goo.gl/maps/DaginnCupP5B7mQx7",
    tel: "03-3573-1121",
    location: "東京・銀座",
    access: [
      "東京メトロ「銀座」駅（C-1・C-2出口） 徒歩約5分",
      "東京メトロ「日比谷」駅（A-11・A-13出口） 徒歩約5分",
      "東京メトロ「有楽町」駅（D-7出口） 徒歩約5分",
      "JR「新橋」駅（北改札出口） 徒歩約6分",
      "JR「有楽町」駅（日比谷口） 徒歩約6分",
    ],
    hid: "rphs_cginzacorridor",
    siteUrl: "https://www.royalparkhotels.co.jp/canvas/ginzacorridor/",
    img: {
      src: "/assets/images/hotels/canvas/img_ginzacorridor.png",
    },
    tag: [
      "東京",
      "#イタリア料理",
      "#バー・ラウンジ",
      "#新規開業（2020年以降）",
    ],
    regions: "関東",
  },
  {
    brand: "canvas",
    title: "ザ ロイヤルパーク キャンバス 名古屋",
    address: "〒450-0002 名古屋市中村区名駅3-23-13",
    mapUrl: "https://goo.gl/maps/2iUHMHCarxr8bb1A6",
    tel: "052-300-1111",
    location: "名古屋",
    access: [
      "JR「名古屋」駅（桜通口） 徒歩約5分",
      "名古屋鉄道「名鉄名古屋」駅（北改札口） 徒歩約5分",
      "名古屋市営地下鉄「名古屋」駅（中央改札口） 徒歩約5分",
    ],
    hid: "rphs_cnagoya",
    siteUrl: "https://www.royalparkhotels.co.jp/canvas/nagoya/",
    img: {
      src: "/assets/images/hotels/canvas/img_nagoya.png",
    },
    tag: ["名古屋", "#大浴場"],
    regions: "中部",
  },
  {
    brand: "canvas",
    title: "ザ ロイヤルパーク キャンバス 京都二条",
    address: "〒604-8381 京都市中京区西ノ京職司町22-7",
    mapUrl: "https://goo.gl/maps/CevejwUB3umKKKF98",
    tel: "075-811-1112",
    location: "京都",
    access: [
      "JR嵯峨野線「二条」駅 徒歩約2分",
      "京都市営地下鉄東西線「二条」駅 徒歩約2分",
    ],
    hid: "rphs_cnijo",
    siteUrl: "https://www.royalparkhotels.co.jp/canvas/kyotonijo/",
    img: {
      src: "/assets/images/hotels/canvas/img_kyotonijo.png",
    },
    tag: ["京都", "#バー・ラウンジ", "#大浴場", "#新規開業（2020年以降）"],
    regions: "関西",
  },
  {
    brand: "canvas",
    title: "ザ ロイヤルパーク キャンバス 大阪北浜",
    address: "〒541-0041 大阪市中央区北浜1-9-8",
    mapUrl: "https://goo.gl/maps/tgLJkjbdAfS1ruHA8",
    tel: "06-6220-1111",
    location: "大阪",
    access: [
      "大阪メトロ堺筋線・京阪電車「北浜」駅（3号出口） 隣接",
      "大阪メトロ御堂筋線「淀屋橋」駅（8号出口） 徒歩約8分",
      "大阪メトロ御堂筋線・JR「新大阪」駅から京阪電車「北浜」駅まで 最短約16分",
    ],
    hid: "rphs_ckitahama",
    siteUrl: "https://www.royalparkhotels.co.jp/canvas/osakakitahama/",
    img: {
      src: "/assets/images/hotels/canvas/img_osakakitahama.png",
    },
    tag: ["大阪", "#バー・ラウンジ"],
    regions: "関西",
  },
  {
    brand: "canvas",
    title: "ザ ロイヤルパーク キャンバス 神戸三宮",
    address: "〒650-0011 神戸市中央区下山手通2-3-1 ",
    mapUrl: "https://goo.gl/maps/u8djaa3syXArvt366",
    tel: "078-391-1110",
    location: "神戸",
    access: [
      "神戸市営地下鉄「三宮」駅（西出口1） 徒歩約2分",
      "阪急電鉄「神戸三宮」駅（西改札口） 徒歩約5分",
      "JR「三ノ宮」駅（西口改札） 徒歩約6分",
      "阪神電鉄「神戸三宮」駅（西口） 徒歩約8分",
      "ポートライナー「三宮」駅 徒歩約9分",
    ],
    hid: "rphs_csannomiya",
    siteUrl: "https://www.royalparkhotels.co.jp/canvas/kobesannomiya/",
    img: {
      src: "/assets/images/hotels/canvas/img_kobesannomiya.png",
    },
    tag: ["神戸", "#バー・ラウンジ", "#新規開業（2020年以降）"],
    regions: "関西",
  },
  {
    brand: "canvas",
    title: "ザ ロイヤルパーク キャンバス 福岡中洲",
    address: "〒810-0801 福岡市博多区中洲5-6-20",
    mapUrl: "https://goo.gl/maps/okU1WjbqerGeDt9j7",
    tel: "092-291-1188",
    location: "福岡",
    access: ["福岡市地下鉄「中洲川端」駅（3番出口） 徒歩約2分"],
    hid: "rphs_cfukuokanakasu",
    siteUrl: "https://www.royalparkhotels.co.jp/canvas/fukuokanakasu/",
    img: {
      src: "/assets/images/hotels/canvas/img_fukuokanakasu.png",
    },
    tag: [
      "福岡",
      "#ウェルネス",
      "#夜景",
      "#バー・ラウンジ",
      "#大浴場",
      "#サウナ",
      "#ビューバス",
      "#新規開業（2020年以降）",
    ],
    regions: "九州",
  },
];

const atoll: Array<hotelData> = [
  {
    brand: "atoll",
    title: "ホテルアトールエメラルド宮古島",
    address: "〒906-0013 沖縄県宮古島市平良字下里108-7",
    mapUrl: "https://goo.gl/maps/cmh3wmTtJ2rWRxqR8",
    tel: "0980-73-9800",
    location: "沖縄・宮古島",
    access: ["宮古空港より車で約15分", "下地島空港より車で約25分"],
    hid: "hotel_atollemerald",
    siteUrl: "https://www.atollemerald.jp/",
    img: {
      src: "/assets/images/hotels/atoll/img_atoll.png",
    },
    tag: ["沖縄", "#ウェルネス", "#日本料理"],
    regions: "沖縄",
  },
];

const marunouchi: Array<hotelData> = [
  {
    brand: "marunouchi",
    title: "丸ノ内ホテル",
    address: "〒100-0005 東京都千代田区丸の内1-6-3",
    mapUrl: "https://maps.app.goo.gl/RkFUitZuxhLRnNQi8",
    tel: "03-3217-1111",
    location: "東京・丸の内",
    access: [
      "JR「東京」駅（丸の内北口）徒歩約1分",
      "東京メトロ「大手町」駅（B2b出口） 徒歩約1分",
      "東京メトロ 丸ノ内線「東京」駅（北改札） 徒歩約2分",
    ],
    hid: "marunouchi_hotel",
    siteUrl: "https://www.marunouchi-hotel.co.jp/",
    img: {
      src: "/assets/images/hotels/marunouchi/img_marunouchi.png",
    },
    tag: ["東京","#夜景","#記念日","#イルミネーション","#アフタヌーンティー","#日本料理","#フランス料理","#バー・ラウンジ", ],
    regions: "関東",
  },
];


export const hotelDatas = [
  ...fullService,
  ...iconic,
  ...the,
  ...canvas,
  ...atoll,
  ...marunouchi,
];
